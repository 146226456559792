<template>
  <div class="video_box" :class="{ paused: !play }" @click="switchPlay(!play)">
    <video
      class="video"
      ref="video"
      :loop="loop"
      :muted="muted"
      :autoplay="autoplay"
      playsinline="true"
      webkit-playsinline="true"
      x5-video-player-type="h5"
      preload="none"
      :poster="poster"
      @ended="videoend()"
    >
      <slot></slot>
    </video>
    <slot v-if="!play && !ended" name="playicon">
    </slot>
    <slot v-if="setreplay && ended" name="replay">
    </slot>
    <slot v-if="(!isready || !play) && !ended" name="cover">
      <div class="cover"></div>
    </slot>
    <slot name="fenxiang"></slot>
  </div>
</template>
<script>
// import { Toast } from 'vant';

// import 'vant/lib/toast/style';
export default {
  props: ["poster", "muted", "autoplay", "loop", "setreplay"],
  data() {
    return {
      play: this.autoplay,
      ended: false,
      isready: false
    };
  },
  mounted() {
    this.$refs.video.addEventListener('loadeddata', () => {
      if(this.$refs.video.readyState >= 2) {
        this.isready = true;
        // Toast.clear();
      }
    });
  },
  methods: {
    switchPlay(parma) {
      if(!this.isready) {
				// Toast.loading({
				// 	duration: 500,
				// 	message: '视频加载中...',
				// });
      }
      this.ended = false;
      this.play = parma;
      console.log('00', parma)
      if (this.play) {
        this.$refs.video.play();
        this.$emit("play", true);
      } else {
        this.$refs.video.pause();
        this.$emit("play", false);
      }
    },
    pauseVideo(state) {
      this.play = state;
      this.ended = false;
      console.log('state', state, this.play, this.ended)
      if (state) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    },
    videoend() {
      this.ended = true;
      this.play = false;
    }
  },
};
</script>
<style  scoped>
.video_box {
  position: relative;
}
  .video_box .paused .video {
      left: -200%;
      width: 0;
      height: 0;
      display: none;
    }
 .video_box .video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .video_box .cover {
    width: 100%;
    height: 100%;
    z-index: 9;
    display: block;
    position: absolute;
  }
</style>
